<template>
    <div class="px-2">
        <div v-if="favorites.includes(property.id)" @click="toggleFavorite(property.id)"
             class="relative text-red cursor-pointer h-4 w-4">
            <svg version="1.1" id="heartFull" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                 y="0px" viewBox="0 0 512 450" fill="currentColor" style="enable-background:new 0 0 512 450;"
                 xml:space="preserve">
                <path d="M374,0c-58.2,0-97.5,29.5-118,50.4C235.5,29.5,196.2,0,138,0C57,0,0,71.9,0,156.7C0,287.8,130.5,388,246.9,447.8 c5.7,2.9,12.5,2.9,18.3,0C381.6,388,512,287.9,512,156.7C512,72,455.1,0,374,0z"/>
            </svg>
        </div>
        <div v-else class="relative black hover:text-red cursor-pointer h-4 w-4" @click="toggleFavorite(property.id)">
            <svg version="1.1" id="heartEmpty" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 512 512" fill="currentColor" style="enable-background:new 0 0 512 512;"
                 xml:space="preserve">
                    <path d="M374,31c-58.214,0-97.482,29.5-118,50.404C235.482,60.5,196.214,31,138,31C57.044,31,0,102.857,0,187.667
			C0,318.82,130.497,419.012,246.861,478.79c5.736,2.947,12.542,2.947,18.278,0C381.563,418.982,512,318.888,512,187.667 C512,103.007,455.085,31,374,31z M256,438.449C157.865,386.596,40,297.982,40,187.667C40,126.922,78.035,71,138,71 c47.307,0,84.768,27.882,101.22,52.382c3.65,5.75,9.923,9.167,16.736,9.2c6.851,0.024,13.153-3.504,16.854-9.245 C273.147,122.813,307.322,71,374,71c59.978,0,98,55.945,98,116.667C472,297.913,353.928,386.705,256,438.449z"/>
            </svg>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                favorites: this.$store.state.favorites
            }
        },
        methods: {
            toggleFavorite(id) {
                this.$store.commit('toggleFavorite', id)
            },
        },
        props: {
            property: Object
        },
    }
</script>
