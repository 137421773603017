<template>

    <div class="w-full sm:w-full p-4 sm:p-0 flex flex-col sm:flex-row bg-white rounded m-3 justify-between items-center hover:shadow">
        <a :href="'/property/'+property.slug" class="rounded">
            <img :src="property.thumbnail" alt="" class="rounded-l block" style="max-width: 300px">
        </a>

        <div class="p-2">
            <div class="flex justify-center items-center mb-2">
                <a :href="'/property/'+property.slug"
                   class="text-center text-blue text-lg flex justify-center items-center no-underline"> {{property.title}} </a>
                <favorite-link :property="property"/>
            </div>

            <property-specs :property="property"/>

            <property-amenities :property="property"/>

        </div>

        <div class="text-center px-4 bg-blue flex flex-col h-auto sm:h-full w-full sm:w-1/5 justify-center rounded-r">
            <a :href="'/property/'+property.slug"
               class="text-center inline-block m-2 p-2 bg-white text-blue rounded no-underline">Book Now</a>

            <div v-if="property.total">
                <div class="text-center text-grey-light text-sm">Total:</div>
                <div class="text-center p-1 text-white text-sm">${{property.total.formatDollars(0)}}</div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            property: Object,
            showRates: Boolean
        },

    }
</script>