<template>
    <div class="flex flex-wrap py-2 justify-center items-center">
        <stripe-element
                type="card"
                :options="stripeOptions"
                :stripe="stripeKey"
                @change="cardComplete = $event.complete"
        >
        </stripe-element>
        <div :class="{'w-full': !inlineButton}">
            <!--<input  type="button" :disabled="!cardComplete" :class="{'opacity-50 , cursor-not-allowed':!cardComplete}" @click="payByCard"-->
                    <!--class="m-4 bg-gold h-10 px-4 rounded shadow text-white" :value="buttonText">-->
            <input type="button" @click="payByCard" :disabled="!cardComplete"  class="m-4 bg-blue h-10 px-4 rounded shadow text-white" :class="{'opacity-50 , cursor-not-allowed':!cardComplete}" :value="buttonText">
        </div>
    </div>
</template>

<script>
    import {StripeElement, Stripe} from "vue-stripe-better-elements"

    export default {
        components: {
            StripeElement
        },
        props: {
            inlineButton: {
                type: Boolean,
                default: true
            },
            buttonText: {
                type: String,
                default: 'Pay'
            }
        },
        data: () => ({
            stripeKey: process.env.MIX_STRIPE_KEY,
            stripeOptions: {
                style: {
                    base: {
                        color: '#32325d',
                        lineHeight: '18px',
                        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                        fontSmoothing: 'antialiased',
                        fontSize: '16px',
                        '::placeholder': {
                            color: '#aab7c4'
                        }
                    },
                    invalid: {
                        color: '#fa755a',
                        iconColor: '#fa755a'
                    }
                }
            },
            cardComplete: false
        }),
        methods: {
            payByCard() {
                Stripe.get("card", this.stripeKey)
                    .createToken()
                    .then(response => {
                        this.$emit('paymentFormSubmitted', response);
                    })
                    .catch(error => {
                        this.$alertErrors(error);
                    });
            }
        },
        watch: {
            cardComplete: function(status){
                this.$emit('cardComplete', status);
            }
        }
    }
</script>

<style lang="scss">
    .StripeElement {
        min-width: 288px;
        max-width: 300px;
        width: 100%;
        border: 1px solid grey;
    }

    .StripeElement.complete {
        border-color: green;
    }

    .StripeElement {
        background-color: white;
        height: 40px;
        padding: 10px 12px;
        border-radius: 4px;
        border: 1px solid transparent;
        box-shadow: 0 1px 3px 0 #e6ebf1;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
    }

    .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
        border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
    }
</style>