<template>
    <div class="flex justify-center mt-4 sm:mt-0 sm:justify-end text-center">

        <v-date-picker
                class="w-full max-w-xs mb-0 mt-0"
                style="max-width: 226px;"
                mode='range'
                v-model='selectedDates'
                :min-date="today"
                :theme-styles="themeStyles"
                :disabled-attribute="disabledAttribute"
                :show-day-popover="false"
                popoverDirection="bottom"
                :popoverContentOffset="14"
                tint-color="#000"
                :select-attribute="selectAttribute"
                show-caps
                @input="updateSearchQuery">
            <div class="flex items-stretch w-full relative hover:shadow" slot-scope='{ inputValue, updateValue }'
                 @mouseover="showingClearButton = showClearButton" @mouseleave="showingClearButton = false">
                <div class="flex -mr-px">
                    <span class="flex items-center leading-normal rounded rounded-r-none border border-r-0 border-white pl-2 whitespace-no-wrap text-grey-dark text-sm bg-white pt-1">
                        <div v-if="showingClearButton" class="cursor-pointer hover:text-black" @click="clearDates">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" class="feather feather-x-circle">
                                <circle cx="12" cy="12" r="10"/>
                                <line x1="15" y1="9" x2="9" y2="15"/>
                                <line x1="9" y1="9" x2="15" y2="15"/>
                            </svg>
                        </div>
                        <div v-else>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 26 26"
                                 fill="none"
                                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                 class="feather feather-calendar">
                                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"/>
                                <line x1="16" y1="2" x2="16" y2="6"/>
                                <line x1="8" y1="2" x2="8" y2="6"/>
                                <line x1="3" y1="10" x2="21" y2="10"/>
                            </svg>
                        </div>
                    </span>
                </div>
                <input type="text"
                       class="flex-shrink flex-grow flex-auto leading-normal flex-1 border border-l-0 h-8 border-white rounded rounded-l-none relative focus:outline-none text-center  shadow-none appearance-none"
                       placeholder="Select Dates" :value='inputValue'
                       @change='updateValue($event.target.value, { formatInput: true, hidePopover: false })'
                       readonly="readonly">
            </div>
        </v-date-picker>

        <div class="flex items-stretch ml-2 mt-0 relative hover:shadow">
            <div class="flex -mr-px h-8">
                <span class="flex items-center leading-normal rounded rounded-r-none border border-r-0 border-white pl-2 whitespace-no-wrap text-grey-dark text-sm bg-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 26 26" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-users">
                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"/>
                        <circle cx="9" cy="7" r="4"/>
                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"/>
                        <path d="M16 3.13a4 4 0 0 1 0 7.75"/>
                    </svg>
                </span>
            </div>
            <input type="number" v-model="guests"
                   class="border border-l-0 h-8 border-white rounded rounded-l-none pl-2 relative focus:outline-none w-10 appearance-none"
                   min="1" oninput="validity.valid||(value='');" @change="updateSearchQuery" @focus="guests = ''">
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                today: new Date(),
                minDate: this.today,
                selectedDates: null,
                guests: 2,
                query: null,
                showingClearButton: false,
                themeStyles: {
                    wrapperStyle: {
                        background: '#fff'
                    },
                    dayCellNotInMonth: {
                        opacity: 0,
                    },
                },
                disabledAttribute: {
                    contentStyle: {
                        opacity: 0.2,
                    },
                },
                selectAttribute: {
                    highlight: {
                        backgroundColor: '#174656',
                        animated: true,
                        opacity: 0.1
                    },
                    contentStyle: {
                        color: '#174656',                 // White text
                    },
                    highlightCaps: params => ({
                        ...(params.onStart && {
                            backgroundColor: 'white',
                            opacity: 0
                        }),
                        ...(params.onEnd && {
                            backgroundColor: 'white',
                            opacity: 0
                        }),

                    })

                }
            };
        },
        computed: {
            showClearButton() {
                return this.selectedDates != null;
            }
        },
        mounted() {
            this.getQuery();
            this.getGuests();
            this.getSelectedDates();
        },
        methods: {
            clearDates(){
                sessionStorage.searchQuery = '';
                this.selectedDates = null;
            },
            dateTimeReviver(key, value) {
                if (key === 'arrival' || key === 'departure') {
                    return this.$moment(value).toDate()
                }
                return value;
            },
            getGuests() {
                if (this.query) {
                    this.guests = this.query.guests || 2;
                }
            },
            getSelectedDates() {
                if (this.query) {
                    if (this.query.arrival && this.query.departure) {
                        this.selectedDates = {
                            start: this.query.arrival,
                            end: this.query.departure
                        }
                    }
                }
            },
            getSearchParams(param) {
                let uri = window.location.search.substring(1);
                let params = new URLSearchParams(uri);
                return params.get(param);
            },
            getQuery() {
                if(this.getSearchParams('arrival') && this.getSearchParams('departure') && this.getSearchParams('guests')){
                    this.selectedDates = {start: this.getSearchParams('arrival'), end: this.getSearchParams('departure')};
                    this.guests = this.getSearchParams('guests');
                    this.updateSearchQuery();
                }
                if (sessionStorage.searchQuery) {
                    this.query = JSON.parse(sessionStorage.searchQuery, this.dateTimeReviver);
                }
            },

            updateSearchQuery() {

                if(this.$moment(this.selectedDates.end).diff(this.selectedDates.start,'days') < 7){
                    this.$alertMessage('Whoops!', 'You need to enter 7 or more days');
                    this.selectedDates = null;
                    sessionStorage.searchQuery = '';
                }
                if (this.selectedDates && this.guests) {
                    let query = {
                        arrival: this.$moment(this.selectedDates.start).format('YYYY-MM-DD'),
                        departure: this.$moment(this.selectedDates.end).format('YYYY-MM-DD'),
                        guests: this.guests
                    };
                    sessionStorage.searchQuery = JSON.stringify(query);
                    this.$emit('updateSearchQuery', query);
                }
            }
        },
    };

</script>
