<template>
    <div class="flex flex-col sm:flex-row justify-center">
        <div class="w-full sm:w-2/3">
            <tiny-slider v-if="images" :mouse-drag="true" :loop="true" mode="gallery" :controls-text="[left,right]" :controls="true"
                         :nav="true" navPosition="bottom" items="1" :lazyload="true" :autoplay="true"
                         :autoplayButtonOutput="false" class="bg-white">
                <img :src="image" v-for="(image, key) in images" :key="image.id" :class="{'tns-lazy-img' : key !== 0}">
            </tiny-slider>
        </div>
        <div class="w-full sm:w-1/3">
            <div class="bg-blue flex flex-col p-4 text-white justify-center items-center h-full ">
                <p class="text-white font-halo font-light text-5xl">Seaside getaways on <br> pristine Pacific Ocean</p>
                <div class="py-3 flex w-full justify-center text-dark">
                    <property-search-form class="w-full sm:justify-center"/>
                </div>
                <a href="/property-search" class="border border-sea hover:bg-sea hover:text-blue m-2 no-underline px-6 py-3 rounded text-sea">Search</a>
            </div>
        </div>
    </div>

</template>

<script>
    import PropertySearchForm from './properties/PropertySearchForm';
    import VueTinySlider from 'vue-tiny-slider';

    export default {
        data() {
            return {
                left: '<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"/></svg>',
                right: '<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"/></svg>'
            }
        },
        props: {
            images: Array
        },
        components: {
            'tiny-slider': VueTinySlider,
            'property-search-form': PropertySearchForm
        }
    }
</script>
