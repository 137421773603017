<template>
    <div class="bg-white p-20 flex flex-col justify-center items-center">
        <p class="text-grey-dark p-3 text-sm text-center leading-loose">Guest Reviews for:<br>
            <span class="text-base ">{{property.title}}</span>
        </p>
        <star-rating :config="config"></star-rating>
        <div v-if="config.rating > 0" class="w-full flex flex-col items-center">
            <div v-for="review in property.reviews" class="border-b border-grey-lighter max-w-md mb-5 text-grey w-full text-sm ">
                <p class="py-1 text-gold text-base">{{review.reviewer}}</p>
                <em class="">{{$moment(review.created).format('MMMM D, YYYY')}}</em>
                <p class="pt-5 pb-5 text-grey-darker leading-normal">{{review.review}}</p>
            </div>
        </div>
        <div v-else class="p-2 text-grey">No reviews yet</div>
    </div>
</template>
<script>
    import starRating from "../../../components/StarRating";

    export default {
        data() {
            return {
                config: {
                    rating: 0,
                    isIndicatorActive: false,
                    style: {
                        fullStarColor: "#edb347",
                        emptyStarColor: "#e2e2e2",
                        starWidth: 20,
                        starHeight: 20
                    }
                },
            };
        },
        props: {
            property: Object
        },
        components: {
            starRating: starRating
        },
        created() {
            if (this.property.reviews.length > 0) {
                this.config.rating = _.meanBy(this.property.reviews, (r) => r.rating);
            }
        }
    };
</script>