<template>
    <div class="m-auto bg-grey-lightest p-2 sm:p-5 font-normal" :class="{'lc-loading' : loading}">
        <div class="max-w-md m-auto my-10">

            <availability-calendar :property="property" @updateQuery="updateDates"/>

            <div class="text-center max-w-sm m-auto p-2 text-sm text-grey-darker uppercase">

                <div class="flex flex-col">
                    <input type="text" name="name" v-model.trim="$v.reservation.name.$model"
                           class="input my-2 w-full"
                           placeholder="Full Name" :class="{ 'error': $v.reservation.name.$error }">
<!--                    <input type="text" name="phone" v-model="$v.reservation.phone.$model" class="input my-2 mx-2 w-full" placeholder="Phone" :class="{ 'error': $v.reservation.phone.$error }">-->
                    <input type="text" name="email" v-model.trim="$v.reservation.email.$model" class="input my-2 w-full" placeholder="Email" :class="{ 'error': $v.reservation.email.$error }">
                </div>

<!--                <input type="text" name="email" v-model.trim="$v.reservation.email.$model" class="input my-2 w-full"-->
<!--                       placeholder="Email" :class="{ 'error': $v.reservation.email.$error }">-->

<!--                <places @addressChanged="setAddress" :value="addressInput" class="input my-2 text-left w-full"-->
<!--                        placeholder="Your Address"></places>-->

<!--                <textarea name="special_requests" id="" v-model="reservation.special_requests" cols="25" rows="4"-->
<!--                          placeholder="Message"-->
<!--                          class="textarea my-2 w-full"></textarea>-->
                <div v-if="reservation.subtotal">


                <div v-if="reservation.total" class="m-5 text-lg normal-case font-light block leading-normal">
                    <div class="text-sm mb-5">{{$moment(reservation.arrival).format('MMMM D, YYYY')}} - {{$moment(reservation.departure).format('MMMM D, YYYY')}}</div>

<!--                    <div class="max-w-sm w-1/2 m-auto flex justify-between items-center text-sm">-->
<!--                        <div>Subtotal:</div>-->
<!--                        <div>${{(reservation.subtotal).formatDollars()}}</div>-->
<!--                    </div>-->

<!--                    <div v-if="reservation.fees" v-for="fee in reservation.fees"-->
<!--                         class="max-w-sm w-1/2 m-auto flex justify-between items-center text-sm">-->
<!--                        <div>{{fee.name}}:</div>-->
<!--                        <div>${{(fee.cost).formatDollars()}}</div>-->
<!--                    </div>-->

                    <div v-if="$moment(reservation.arrival).diff($moment(), 'days') > 60" class="max-w-sm w-1/2 m-auto flex justify-between items-center">
                        <div>Deposit Due:</div>
                        <div>${{(reservation.total / 2).formatDollars()}}</div>
                    </div>
                    <div  class="max-w-sm w-1/2 m-auto flex justify-between items-center mt-2" >
                        <div>Total:</div>
                        <div>${{(reservation.total).formatDollars()}}</div>
                    </div>
                </div>
<!--                <div v-if="discount" class="bold text-blue">-->
<!--                    {{discount.name}} Applied !-->
<!--                </div>-->

<!--                <div v-if="!discount">-->
<!--                    <a @click="haveCoupon = !haveCoupon"-->
<!--                       class="block m-2 text-blue cursor-pointer hover:text-black">Have a Discount Code?</a>-->
<!--                    <div v-if="haveCoupon" class="flex m-2 justify-center">-->
<!--                        <input type="text" name="discount_code" class="input m-0 mx-1" placeholder="Discount Code"-->
<!--                               v-model="reservation.discount_code">-->
<!--                        <button class="bg-blue h-10 px-4 rounded text-white mx-1" @click="getTotal">Apply</button>-->
<!--                    </div>-->
<!--                </div>-->

                    <a class="no-underline p-3 mt-2 bg-blue text-white rounded inline-block cursor-pointer" @click="submitReservation">Reserve Now</a>
                </div>

                <div v-else class="my-10 font-bold">
                    <div v-if="reservation.arrival">
                        Property not available for these dates.
                    </div>
                    <div v-else>
                        Choose dates to see availability.
                    </div>
                </div>

<!--                <div class="m-auto my-3">-->
<!--                    <payment-form button-text="Reserve Now" :inline-button="false"-->
<!--                                  @paymentFormSubmitted="submitReservation"/>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
    import PaymentForm from '../../stripe/PaymentForm'
    import places from '../../Places'
    import AvailabilityCalendar from './PropertyAvailabilityCalendar';
    import {required} from 'vuelidate/lib/validators'

    export default {
        data() {
            return {
                discount: null,
                loading: false,
                reservation: {
                    property_id: this.property.id,
                    arrival: null,
                    departure: null,
                    guests: null,
                    nights: null,
                    name: '',
                    phone: '',
                    email: '',
                    fees: null,
                    subtotal: null,
                    total: null,
                    special_requests: null,
                    status: 'pending',
                    discount_code: null,
                },
                addressInput: null,
                haveCoupon: false,
            }
        },
        validations: {
            reservation: {
                name: {
                    required,
                },
                email: {
                    required,
                }
            }
        },
        props: {
            property: Object
        },
        methods: {
            getGuests() {
                if (localStorage.searchQuery) {
                    this.reservation.guests = this.query.guests || 2;
                } else {
                    this.reservation.guests = 2;
                }
            },
            getNights() {
                return Math.abs(this.$moment(this.reservation.start).diff(this.reservation.end, 'days'));
            },
            getSearchQuery() {
                if (localStorage.searchQuery) {
                    this.query = JSON.parse(localStorage.searchQuery, this.dateTimeReviver);
                } else {
                    this.query = null
                }
            },
            getTotal() {
                return axios.post('/api/properties/' + this.property.id + '/calculateRate', {
                    start: this.$moment(this.reservation.arrival).format('YYYY-MM-DD'),
                    end: this.$moment(this.reservation.departure).format('YYYY-MM-DD'),
                    discount_code: this.reservation.discount_code
                }).then(response => {
                    console.log(response.data)
                    this.reservation.fees = response.data.fees.fees;
                    this.reservation.subtotal = response.data.subtotal;
                    this.reservation.total = response.data.total;
                    this.discount = response.data.discount;
                }).catch(error => {
                    this.$alertErrors(error);
                })
            },
            setAddress(output) {
                if (output) {
                    this.reservation.address = output.name;
                    this.reservation.state = output.administrative;
                    this.reservation.country = output.countryCode.toUpperCase();
                    this.reservation.city = output.city;
                    this.reservation.postcode = output.postcode;
                }
            },
            submitReservation() {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    this.loading = true;
                    this.reservation.nights = this.getNights();
                    this.reservation.hold = true;
                    axios.post('/api/reservations', this.reservation)
                        .then(response => {
                            window.location.href = '/thank-you/'
                        }).catch(error => {
                        this.loading = false;
                        this.$alertErrors(error);
                    });
                }
            },
            updateDates(start, end) {
                this.reservation.arrival = this.$moment(start).format('YYYY-MM-DD');
                this.reservation.departure = this.$moment(end).format('YYYY-MM-DD');
                this.getTotal();
            },
        },
        mounted() {
            this.getSearchQuery();
            this.getGuests();
            if (window.user && window.user.name === 'Jason Young') {
                this.reservation.name = 'Jason Young';
                this.reservation.email = 'jason@hotlavadesign.com';
            }
        },
        components: {
            places,
            PaymentForm,
            AvailabilityCalendar
        }
    }
</script>