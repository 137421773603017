import Vue from 'vue';

Array.prototype.sum = function (prop) {
    let total = 0;
    for (let i = 0, _len = this.length; i < _len; i++) {
        total += this[i][prop]
    }
    return total
};

Number.prototype.formatDollars = function (decimals = 2) {
    let  val = (parseInt(this) / 100).toFixed(decimals).replace(',', '.');
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

Vue.prototype.$alertErrors = function(errorData){
    let errors = [];
    console.log(errorData);
    if(errorData.response.data.errors){
        Object.values(errorData.response.data.errors).forEach(function (message) {
            errors.push(message);
        });
    }
    let errorOutput = document.createElement("div");
    errorOutput.innerHTML = errors.join("<br>");
    swal({
        title: errorData.response.data.message,
        content: errorOutput,
        icon: "error"
    })
};

Vue.prototype.$alertMessage = function(title, message){

    let messageOutput = document.createElement("div");
    messageOutput.innerHTML = message;
    swal({
        title: title,
        content: messageOutput,
        icon: "info"
    })
};

export default {
    methods: {
        formatTotal (value) {
            let val = (value / 100).toFixed(2).replace(',', '.');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        alertSomething(){
            console.log('alerted');
            alert('this!');
        },

    }
}