<template>
    <div>
        <div class="p-4 bg-grey-lightest cursor-pointer">
            <VueSignaturePad
                    height="150px"
                    ref="signaturePad"
            />
            <div class="-mt-10 border-b uppercase text-grey ">
                <span class="hidden-print">Sign Here:</span>
            </div>
            <div class="text-center">{{signer}}</div>
        </div>
        <div class="mt-5 flex justify-between hidden-print">
            <button @click="save" class="bg-blue text-white uppercase text-sm rounded p-2">Confirm Signature</button>
            <button @click="clear" class="uppercase text-sm text-grey-light rounded p-2">Reset</button>
        </div>
    </div>
</template>
<script>
    import VueSignaturePad from 'vue-signature-pad';

    export default {
        data() {
            return {
                signature: null,
            }
        },
        props: {
            signer: String,
            action: String,
            reservation: Object
        },
        methods: {
            clear() {
                this.$refs.signaturePad.undoSignature();
            },
            save() {
                this.signature = this.$refs.signaturePad.saveSignature();
                axios.post(this.action, {reservation_id: this.reservation.id, signature: this.signature.data})
                    .then(response => {
                        console.log(response.data);
                        window.location.href = '/guest-portal/' + this.reservation.confirmation_code;
                    }).catch(error => {
                    this.$alertErrors(error);
                });
            }
        },
        components: {
            VueSignaturePad
        }
    }
</script>