<template>
    <a href="/property-favorites" class="cursor-pointer p-2 text-blue">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             x="0px" y="0px" width="20px" height="15px" viewBox="0 0 20 20"
             style="enable-background:new 0 0 20 20;" fill="currentColor" xml:space="preserve">
                        <path class="st0" d="M10.6,2.5c0.6-0.6,1.3-1.2,2.1-1.6c2.2-1.5,5.1-0.9,6.6,0.2c1.1,0.9,1.6,2,1.8,3.4c0.3,1.9-0.4,3.5-1.5,5
		c-0.6,0.8-1.4,1.5-2.1,2.2c-2.1,2-4.2,4-6.3,6.1c-0.4,0.4-0.8,0.4-1.2,0c-2.4-2.3-4.7-4.6-7.1-6.9C1.9,10.1,1.2,9.1,0.6,8
		c-0.7-1.4-0.8-2.9-0.3-4.4C0.7,2,1.7,1,3.2,0.4C5-0.2,6.8-0.2,8.4,0.9c0.7,0.5,1.4,1,2,1.6C10.5,2.5,10.5,2.5,10.6,2.5z"/>
                </svg>
        <div v-if="favorites.length" class="-mt-8 absolute bg-sea flex h-4 items-center justify-center leading-normal ml-3 no-underline p-0 rounded-full text-blue text-center text-xs w-4">{{favorites.length}}</div>
    </a>
</template>
<script>
    export default{
        data() {
            return {
                favorites: this.$store.state.favorites
            }
        },

    }
</script>