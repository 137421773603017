<template>
    <div class="px-2">
        <div class="flex-1 justify-center m-auto max-w-md">
            <div class="flex px-5 py-1 justify-around items-center max-w-sm m-auto text-sm text-dark">
                <div class="flex justify-center items-center h-auto">
                    <div class="p-1"><img src="/img/icons/bedrooms.svg" alt="" width="23"
                                          height="35"></div>
                    <div class="p-1">{{property.bedrooms}} Bedrooms</div>
                </div>
                <div class="flex justify-center items-center h-auto">
                    <div class="p-1"><img src="/img/icons/bathrooms.svg" alt="" width="35"
                                          height="35"></div>
                    <div class="p-1">{{property.bathrooms}} Bathrooms</div>
                </div>
            </div>

            <div class="flex px-5 py-1 justify-around items-center max-w-sm m-auto mb-3 text-sm text-dark">
                <div class="flex justify-center items-center h-auto">
                    <div class="p-1"><img src="/img/icons/beds.svg" alt="" width="30"
                                          height="35"></div>
                    <div class="p-1">{{property.beds}} Beds</div>
                </div>
                <div class="flex justify-center items-center h-auto">
                    <div class="p-1"><img src="/img/icons/location.svg" alt="" width="30"
                                          height="35"></div>
                    <div class="p-1">{{property.category}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default{
        props:{
            property: Object
        }
    }
</script>