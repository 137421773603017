<template>
    <div class="menu-items hidden sm:flex text-xs justify-between lg:text-sm items-center">
        <div @mouseover="showCategories = true" @mouseleave="showCategories = false">
            <a href="/property-search" class="menu-item">Property Rentals</a>
            <div v-if="showCategories" class="bg-white absolute flex flex-col p-4 shadow ">
                <a href="/property-search/oceanfront" class="menu-item p-1">Oceanfront</a>
                <a href="/property-search/ocean-view" class="menu-item p-1">Ocean View</a>
                <a href="/property-search/garden-view" class="menu-item p-1">Garden View</a>
            </div>
        </div>
        <a href="/for-sale" class="menu-item">For Sale</a>
        <a href="/amenities" class="menu-item">Amenities</a>
        <a href="/attractions" class="menu-item">Local Attractions</a>
        <a href="/about" class="menu-item">About Us</a>
        <a href="/contact" class="menu-item">Contact Us</a>

        <div class="icons flex justify-center ">
            <a href="mailto:nicole@seabluffe.com" class="p-2 text-blue">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                     x="0px" y="0px" width="21.4px" height="13.8px" viewBox="0 0 21.4 13.8"
                     style="enable-background:new 0 0 21.4 13.8;" fill="currentColor" xml:space="preserve">
                    <path class="st0" d="M0.7,1.3c0.6,0.3,8.6,4.6,8.9,4.8c0.3,0.2,0.7,0.2,1.1,0.2c0.4,0,0.8-0.1,1.1-0.2c0.3-0.2,8.3-4.5,8.9-4.8
		C21.2,1,21.8,0,20.7,0H0.6C-0.4,0,0.1,1,0.7,1.3z M20.9,3.7c-0.7,0.3-8.7,4.6-9.1,4.8c-0.4,0.2-0.7,0.2-1.1,0.2
		c-0.4,0-0.7,0-1.1-0.2C9.2,8.2,1.1,4,0.5,3.7C0,3.4,0,3.7,0,3.9c0,0.2,0,8.7,0,8.7c0,0.5,0.7,1.1,1.2,1.1h19c0.5,0,1.2-0.6,1.2-1.1
		c0,0,0-8.5,0-8.7C21.4,3.7,21.4,3.4,20.9,3.7z"/>
                </svg>
            </a>
            <navigation-favorite-link/>
        </div>

    </div>
</template>
<script>
    export default {
        data() {
            return {
                showCategories: false
            }
        }
    }
</script>