<template>
    <div class="bg-grey-lighter pb-10 h-full" :class="{'lc-loading' : loading}">
        <h3 class="max-w-md m-auto text-center py-10">Favorite Properties</h3>
        <div v-if="favoriteProperties.length < 1"
             class="flex flex-col justify-center items-center h-halfscreen top-0 left-0 w-full text-dark z-0 p-4">
            <div class="text-center leading-normal">
                <h3 class="mb-4">It appears you have no favorite properties yet.</h3>
            </div>
            <div class="w-full">

            </div>
        </div>

        <div class="flex justify-center items-center max-w-2xl m-auto">
            <div class="w-full">
                <div class="flex flex-wrap">
                    <property-card v-for="property in favoriteProperties" :key="property.id"
                                   :property="property"></property-card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import PropertyCard from './PropertyCard';

    export default {
        data() {
            return {
                selectedDates: null,
                guests: 2,
                favoriteProperties: {},
                loading: false,
                sortType: null,
            }
        },
        props: {
            default_properties: Array,
        },
        methods: {
            getFavoriteProperties() {
                let favoriteIds = JSON.parse(localStorage.getItem('favorites')) || [];
                this.favoriteProperties = _.filter(this.default_properties, function ($property) {
                        return favoriteIds.indexOf($property.id) >= 0;
                });
            },

        },
        mounted() {
            this.getFavoriteProperties();
        },
        components: {
            PropertyCard,
        }
    };
</script>