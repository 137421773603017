<template>
    <div class="flex flex-wrap justify-center">
        <div v-for="amenity in property.amenities" class="px-2 text-center">
            <img :src="'/storage/'+ amenity.image" alt:="amenity.name" width="30" height="30" class="m-auto">
            <p class="text-xs">{{amenity.name}}</p>
        </div>
    </div>
</template>

<script>
    export default {
      props:{
          property: Object
      }
    }
</script>