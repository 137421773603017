<template>
    <div class="w-full text-center md:text-left">
        <div v-if="query">
            <a href="#reservation-form" class="md:mx-2 mb-2 m-2 p-3 bg-blue h-10 px-4 rounded shadow text-white no-underline inline-block">Reserve Now</a>
        </div>
        <div v-else>
            <div class="p-2 text-sm"> From ${{property.weekly_summer_rate.formatDollars()}} / night</div>

            <a href="#reservation-form" class="md:mx-2 mb-2 m-2 p-3 bg-blue h-10 px-4 rounded shadow text-white no-underline inline-block">Check Availability</a>
        </div>
    </div>
</template>

<script>
export default{
    data(){
        return{
            query: null
        }
    },
    props:{
        property: Object
    },
    methods:{
        getSearchQuery() {
            if (localStorage.searchQuery) {
                this.query = JSON.parse(localStorage.searchQuery, this.dateTimeReviver);
            } else {
                this.query = null
            }
        },
    },
    created(){
        this.getSearchQuery();
    }
}
</script>