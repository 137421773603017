<template>
    <div class="w-full xs:w-full sm:w-1/2 md:w-1/3">
        <a :href="'/property/'+property.slug"
           class="bg-white block m-3 rounded text-dark no-underline text-black hover:shadow">

            <img :src="property.thumbnail" alt="" class="rounded-t">

            <div class="flex justify-center items-center my-2">
                <a :href="'/property/'+property.slug"
                   class="text-center text-blue text-lg flex justify-center items-center no-underline"> {{property.title}} </a>
                <favorite-link :property="property"/>
            </div>


            <property-specs :property="property"  />

        </a>
    </div>
</template>
<script>
    import PropertySpecs from './PropertySpecs';
    export default {
        props: {
            property: Object,
            showRates: Boolean
        },
        components: {
            PropertySpecs
        }
    }
</script>