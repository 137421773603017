require('./bootstrap');

import Vue from 'vue';
import mixins from './mixins';

// Vuex
import Vuex from 'vuex'
Vue.use(Vuex);
import 'es6-promise/auto'

// Moment
import moment from 'moment-timezone';
import {extendMoment} from 'moment-range';

Object.defineProperty(Vue.prototype, '$moment', {value: extendMoment(moment)});


import VCalendar from 'v-calendar';
import 'v-calendar/lib/v-calendar.min.css';

Vue.use(VCalendar, {
    firstDayOfWeek: 1,
});

//Validation
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate);

//SweetAlert
import swal from 'sweetalert';
window.swal = swal;

//Toast
import Toasted from 'vue-toasted';
Vue.use(Toasted);

Vue.component('property-favorites', require('./components/public/properties/PropertyFavorites').default);
Vue.component('property-search', require('./components/public/properties/PropertySearch').default);
Vue.component('property-specs', require('./components/public/properties/PropertySpecs').default);
Vue.component('property-amenities', require('./components/public/properties/PropertyAmenities').default);
Vue.component('property-images', require('./components/public/properties/PropertyImages').default);
Vue.component('property-reviews', require('./components/public/properties/PropertyReviews').default);
Vue.component('reservation-form', require('./components/public/properties/ReservationForm').default);
Vue.component('check-rates-form', require('./components/public/properties/CheckRatesForm').default);
Vue.component('mobile-navigation', require('./components/public/MobileNavigation').default);
Vue.component('main-slider', require('./components/public/MainSlider').default);
Vue.component('navigation', require('./components/public/Navigation').default);
Vue.component('navigation-favorite-link', require('./components/public/NavigationFavoriteLink').default);
Vue.component('favorite-link', require('./components/public/properties/PropertyFavoriteLink').default);
Vue.component('featured-properties', require('./components/public/properties/FeaturedProperties').default);
Vue.component('document-signature', require('./components/DocumentSignature').default);

const store = new Vuex.Store({
    state: {
        favorites: JSON.parse(localStorage.getItem('favorites')) || []
    },
    mutations: {
        toggleFavorite(state, id) {
            let index = state.favorites.indexOf(id);

            if (index < 0) {
                state.favorites.push(id);
            } else {
                state.favorites.splice(index, 1);
            }
            localStorage.setItem('favorites', JSON.stringify(state.favorites));
        },
        increment (state) {
            state.count++
        }
    }
});


const app = new Vue({
    el: '#app',
    mixins: [mixins],
    store
});

