<template>
    <div class="sm:hidden">
        <div class="p-1 text-white fixed top-0 left-0 z-30 w-full bg-white flex items-center justify-start">
            <div class="w-1/3 hamburger hamburger--3dy outline-none appearance-none " :class="{'is-active' : isActive }" @click="isActive = !isActive" >
                <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                </span>
            </div>

            <a href="/" class="w-1/3 text-center"><img src="/img/logo-icon.svg" alt="London Connection" width="75"
                                                       class="text-blue"></a>
        </div>
        <transition name="menu-popover">
            <div class="bg-white h-screen z-20 w-full fixed text-white text-center mobile-menu" v-if="isActive">
                <div class="flex flex-col justify-center items-center h-full">
                    <a href="/" class="m-2"><img src="/img/logo-icon.svg" alt="" width="150"></a>
                    <a href="/property-search" class="menu-item">Property Search</a>
                    <a href="/for-sale" class="menu-item">For Sale</a>
                    <a href="/amenities" class="menu-item">Amenities</a>
                    <a href="/attractions" class="menu-item">Local Attractions</a>
                    <a href="/about" class="menu-item">About Us</a>
                    <a href="/contact" class="menu-item">Contact Us</a>
                </div>
            </div>
        </transition>
    </div>

</template>
<script>
    export default {
        data() {
            return {
                isActive: false,
            }
        }
    }
</script>