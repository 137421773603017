<template>
    <div id="map" class="flex flex-wrap" style="height: 75vh;">
        <transition name="menu-popover">
            <div v-if="previewProperty" class="absolute bg-white flex h-full bottom-0 z-10 sm:w-auto w-full">
                <div class="bg-white m-auto p-4 no-underline text-center">
                    <div class="text-right cursor-pointer block" @click="removePreview()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                             class="feather feather-chevrons-left">
                            <polyline points="11 17 6 12 11 7"/>
                            <polyline points="18 17 13 12 18 7"/>
                        </svg>
                    </div>
                    <property-specs :property="previewProperty"></property-specs>
                    <a :href="'/property/'+previewProperty.slug"
                       class="bg-blue inline-block m-2 no-underline p-2 rounded self-center text-center text-white">View Property</a>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
    import mapboxgl from 'mapbox-gl';
    import PropertySpecs from './PropertySpecs';

    mapboxgl.accessToken = 'pk.eyJ1IjoibGF2YWxhYnMiLCJhIjoiY2pza3Q5aGE3MmkzbjQzcXNnaHJ4c2h5MyJ9.4gA4QzlhsqVDyyoriAbUaA';
    export default {
        data() {
            return {
                map: null,
                markers: [],
                previewProperty: null
            }
        },
        props: {
            properties: Array,
        },
        methods: {
            getGeoJson(properties) {
                return JSON.stringify({
                    type: "FeatureCollection",
                    features: properties.map(property => {
                        return {
                            type: "Feature",
                            properties: property,
                            geometry: {
                                type: "Point",
                                coordinates: [property.longitude, property.latitude]
                            },
                        };
                    })
                });
            },
            removePreview() {
                this.previewProperty = null;
            },
            updateProperties(properties) {
                let map = this.map;
                if (map.getSource('availableProperties')) {
                    let availableProperties = JSON.parse(this.getGeoJson(properties));
                    map.getSource('availableProperties').setData(availableProperties);
                }
            },
            initMap() {
                this.markers = [];
                // let sw = new mapboxgl.LngLat(51.42545, -0.32769);
                // let ne = new mapboxgl.LngLat(51.58183, 0.00441);
                // let bounds = new mapboxgl.LngLatBounds(sw, ne);
                this.map = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/lavalabs/cjskuu89i7vr01frw2tpmrmw9',
                    center: [-117.309737, 33.078156],
                    zoom: 17,
                    minZoom: 16,
                    maxZoom: 20,
                    bearing: -45.60,
                    pitch: 60.00,
                    scrollZoom: false,
                });
                this.setMarkers(this.properties);
            },

            getPropertyPreview(property) {
                return '<property-specs :property="' + property + '"></property-specs>';
            },
            setMarkers() {
                let map = this.map;
                let nav = new mapboxgl.NavigationControl({showCompass: false});
                let geoJson = JSON.parse(this.getGeoJson(this.properties));
                map.on('load', function () {
                    map.loadImage("/img/custom-marker.png", function(error, image) {
                        if (error) throw error;
                        map.addImage("custom-marker", image);

                        map.addControl(nav, 'top-right');

                        map.addSource('availableProperties', {type: 'geojson', data: geoJson});

                        map.addLayer({
                            id: "availableProperties",
                            type: "symbol",
                            source: "availableProperties",
                            layout: {
                                "icon-image": "custom-marker",
                                "icon-size": .3,
                                "icon-ignore-placement": true
                            }
                        });
                    });

                    map.on('click', 'availableProperties', function (e) {
                        this.previewProperty = e.features[0].properties;
                        map.flyTo({center: e.features[0].geometry.coordinates, speed: 0.4});
                    }.bind(this));

                    map.on('mouseenter', 'availableProperties', function () {
                        map.getCanvas().style.cursor = 'pointer';
                    });

                    map.on('mouseleave', 'availableProperties', function () {
                        map.getCanvas().style.cursor = '';
                    });
                }.bind(this));
            }
        },
        mounted() {
            this.initMap();
        },
        watch: {
            properties: function (properties) {
                this.updateProperties(properties);
            }
        },
        components: {
            PropertySpecs
        }

    }
</script>