<template>
    <div v-if="properties" class="p-4 flex flex-wrap flex-col sm:flex-row max-w-2xl m-auto">
        <div></div>
        <property-card v-for="property in properties" :property="property" :key="property.id" :showRates="false"/>
    </div>
</template>
<script>
    import PropertyCard from './PropertyCard';
    export default{
        data(){
            return{

            }
        },
        props:{
            properties: Array,
        },
        components:{
            PropertyCard
        }
    }
</script>