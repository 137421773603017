<template>
    <div>
        <v-date-picker
                mode='range'
                v-model='selectedDates'
                :theme-styles="themeStyles"
                :select-attribute="selectAttribute"
                :disabled-attribute="disabledAttribute"
                :disabled-dates="disabledDates"
                :attributes="reservationHighlights"
                :min-date="today"
                tint-color="#000"
                :is-double-paned="true"
                :show-day-popover="false"
                :is-inline="true"
                :is-linked="true"
                is-expanded
                @update:fromPage="setCalendarStart"
                @update:toPage="setCalendarEnd"
                @input="checkMinimumLength"
                @change="updateSearchQuery"
                show-caps>
        </v-date-picker>
        <div class="flex items-center text-center max-w-sm m-auto p-2 pb-0 text-sm text-grey-darker uppercase">
            <div class="w-full">
                <div class="no-input m-2">
                    <div v-if="nights" class="text-center">
                        {{nights}} Nights
                    </div>
                    <div v-else>Choose Dates</div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex justify-center w-auto">
                    <div class="input my-2 ml-2 w-auto">
                        <input type="number" name="guests" v-model="guests"
                               class="" placeholder="Guests" :min="property.min_guests"
                               :max="property.max_guests"> Guests
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                today: new Date(),
                guests: 2,
                query: null,
                selectedDates: null,
                disabledDates: null,
                reservationHighlights: null,
                calendarStart: null,
                calendarEnd: null,
                themeStyles: {
                    wrapper: {
                        background: '#fff',
                        color: '#202020'
                    },
                    dayCellNotInMonth: {
                        opacity: 0,
                    },
                    verticalDivider: {
                        borderLeft: '2px solid #f9f9f9'
                    }
                },
                disabledAttribute: {
                    contentStyle: {
                        backgroundColor: '#eee',
                        opacity: 0.2,
                        borderRadius: 0,
                        cursor: 'not-allowed',
                    },
                },
                selectAttribute: {
                    highlight: {
                        backgroundColor: '#9FE2BD',
                        animated: true,
                        opacity: 0.3
                    },
                    contentStyle: {
                        color: '#9FE2BD',                 // White text
                    },
                    highlightCaps: params => ({
                        ...(params.onStart && {
                            backgroundColor: 'white',
                            opacity: 0
                        }),
                        ...(params.onEnd && {
                            backgroundColor: 'white',
                            opacity: 0
                        }),

                    })
                }
            }
        },
        methods: {
            checkMinimumLength(value) {
                let range = this.$moment(value.end).diff(value.start, 'days');
                if (range < this.property.min_nights) {
                    this.selectedDates = null;
                    swal('A minimum stay of ' + this.property.min_nights + ' nights is required in this property.')
                }
            },
            dateTimeReviver(key, value) {
                if (key === 'arrival' || key === 'departure') {
                    return this.$moment(value).toDate()
                }
                return value;
            },
            getAvailableDates(results) {
                return Object.keys(results)
                    .reduce(function (r, k) {
                        return r.concat(results[k]);
                    }, [])
                    .filter(object => {
                        return object.reservation !== null
                    }).map(function (value) {
                        return value.date;
                    });
            },
            getReservationHighlights(results) {
                let highlights = Object.keys(results)
                    .reduce(function (r, k) {
                        return r.concat(results[k]);
                    }, [])
                    .filter(object => {
                        return object.reservation;
                    }).map(function (value) {
                        return value.reservation;
                    });


                return this.unique(highlights,'arrival').map(function(reservation){
                    return {
                        highlight: {
                            backgroundColor: '#eee',
                            opacity: 0.8,
                        },
                        highlightCaps: params => ({
                            ...(params.onStart && {
                                backgroundColor: 'white',
                                opacity: 0
                            }),
                            ...(params.onEnd && {
                                backgroundColor: 'white',
                                opacity: 0
                            }),

                        }),
                        dates: [
                            {
                                start: this.$moment(reservation.arrival).toDate(),  // Jan 1st
                                end: this.$moment(reservation.departure).toDate(),    // - Jan 4th
                            },
                        ],
                    }
                }.bind(this));
            },
            unique(array, propertyName) {
                return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
            },
            getUnavailableDates(results) {
                return Object.keys(results)
                    .reduce(function (r, k) {
                        return r.concat(results[k]);
                    }, [])
                    .filter(object => {
                        return object.reservation !== null &&
                            this.$moment(object.reservation.arrival).calendar() !==
                            this.$moment(object.date).calendar() || object.closure !== null;
                    }).map(function (value) {
                        return value.date;
                    });
            },
            getAvailability(start, end) {
                return axios.post('/api/properties/calendarDetails', {
                    ids: [this.property.id],
                    calendar_start: start,
                    calendar_end: end
                }).then(response => {
                    let results = response.data[0];
                    this.disabledDates = this.getUnavailableDates(results);
                    this.reservationHighlights = this.getReservationHighlights(results);
                }).catch(error => {
                    this.$alertErrors(error);
                })
            },
            getGuests() {
                if (sessionStorage.searchQuery) {
                    this.guests = this.query.guests || 2;
                } else {
                    this.guests = 2;
                }
            },
            getSearchQuery() {
                if (sessionStorage.searchQuery) {
                    this.query = JSON.parse(sessionStorage.searchQuery, this.dateTimeReviver);
                } else {
                    this.query = null
                }
            },
            getSelectedDates() {
                if (this.query !== null) {
                    if (this.query.arrival && this.query.departure) {
                        this.selectedDates = {
                            start: this.query.arrival,
                            end: this.query.departure
                        }
                    }
                } else {
                    this.selectedDates = null
                }
            },
            setCalendarStart(page) {
                if (page) {
                    let startString = String(page.year + '-' + page.month + '-1');
                    this.calendarStart = this.$moment(startString, 'YYYY-M-DD').format('YYYY-MM-DD');
                }
            },
            setCalendarEnd(page) {
                if (page) {
                    let endString = page.year + '-' + page.month;
                    this.calendarEnd = this.$moment(endString, 'YYYY-M').endOf('month').format('YYYY-MM-DD');
                    this.getAvailability(this.calendarStart, this.calendarEnd);
                } else {
                    this.getAvailability(this.$moment().format('YYYY-MM-DD'), this.$moment().add(1, 'month').endOf('month').format('YYYY-MM-DD'));
                }
            },
            updateSearchQuery() {
                if (this.selectedDates !== null && this.guests !== null) {
                    let query = {
                        arrival: this.$moment(this.selectedDates.start).format('YYYY-MM-DD'),
                        departure: this.$moment(this.selectedDates.end).format('YYYY-MM-DD'),
                        guests: this.guests
                    };
                    this.$emit('updateQuery', query.arrival, query.departure);
                    sessionStorage.searchQuery = JSON.stringify(query);
                }
            }
        },
        computed: {
            nights: function () {
                if (this.selectedDates) {
                    return this.$moment(this.selectedDates.end).diff(this.selectedDates.start, 'days')
                } else {
                    return null
                }
            },
        },
        watch: {
            selectedDates() {
                return this.updateSearchQuery();
            },
            guests() {
                return this.updateSearchQuery();
            }
        },
        created() {
            this.getSearchQuery();
            this.getSelectedDates();
            this.getGuests();
        },
        props: {
            property: Object,
        }
    }
</script>

<style>
    .c-pane-container {
        border: 1px solid transparent;
        box-shadow: 0 1px 3px 0 #e6ebf1;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
    }
</style>